import styled from "styled-components";
import Img from "gatsby-image";

export const SocialIcon = styled(Img)`
  width: 2.1vw;
  height: 2.1vw;
  transition: transform 0.2s ease;
  :hover {
    transform: scale(1.05);
  }
  @media only screen and (max-width: 575px) {
    width: 7.5vw;
    height: 7.5vw;
  }
  ${props =>
    props.round &&
    `
        width: 2vw;
        height: 2vw;
        @media only screen and (max-width: 575px) {
            width: 7vw;
            height: 7vw;
        }
    `}
`;

export const AuthorsSection = styled.section`
  margin-bottom: 4vw;
  @media only screen and (max-width: 575px) {
    margin-bottom: 5vw;
  }
`;

export const AuthorsWrapper = styled.section`
  padding: 4.7vw 4vw 4.7vw 4.7vw;
  border-radius: 0.6vw;
  background: white;
  @media only screen and (max-width: 575px) {
    padding: 6vw;
    border-radius: 2.5vw;
  }
`;

export const AuthorsTitle = styled.h2`
  color: var(--granich-black);
  font-size: 4.55vw;
  letter-spacing: -0.2vw;
  font-weight: 700;
  line-height: 0.87;
  margin-top: -0.6vw;
  margin-bottom: 2.3vw;

  @media only screen and (max-width: 575px) {
    font-size: 11.3vw;
    letter-spacing: -0.7vw;
    line-height: 1;
    margin-right: 0;
    margin-bottom: 2vw;
  }
`;

export const AuthorsTeachers = styled.div`
  display: flex;
  margin-bottom: 2.5vw;
  @media only screen and (max-width: 575px) {
    flex-direction: column;
    margin-bottom: 0vw;
  }
`;

export const AuthorsText = styled.div`
  display: flex;
  @media only screen and (max-width: 575px) {
    flex-direction: column;
  }
`;
export const AuthorsQuote = styled.div`
  font-size: 1.4vw;
  color: var(--granich-black);
  padding-left: 1.5vw;
  border-left: 2.5px solid var(--granich-red);
  margin-right: 1vw;
  font-weight: 500;
  min-width: 27.8vw;
  width: 27.8vw;
  line-height: 1.45;
  @media only screen and (max-width: 575px) {
    font-size: 5.1vw;
    letter-spacing: -0.1vw;
    padding-left: 3.5vw;
    margin-right: 0;
    margin-bottom: 3vw;
    min-width: 100%;
    width: 100%;
  }
`;
export const AuthorsDescr = styled.div`
  font-size: 1.2vw;
  color: var(--granich-grey);
  line-height: 1.45;
  a {
    border-bottom: 1px solid var(--granich-light-grey);
    :hover {
      border-color: var(--granich-grey);
    }
  }
  @media only screen and (max-width: 575px) {
    font-size: 3.8vw;
  }
`;

export const TeacherWrapper = styled.div`
  background: #e8e8e8;
  border-radius: 0.6vw;
  padding: 1.7vw 2vw 1.7vw 2vw;
  display: flex;
  flex-direction: column;
  min-height: 16.1vw;
  position: relative;
  margin-right: 6.3vw;
  :after {
    content: "+";
    position: absolute;
    color: var(--granich-black);
    font-weight: 600;
    top: 50%;
    transform: translateY(-50%);
    right: -4.4vw;
    font-size: 4vw;
  }
  :last-child {
    margin-right: 0;
    :after {
      display: none;
    }
  }
  ${props =>
    props.numberOfTeachers === 3 &&
    `
        padding: 1.4vw;
        margin-right: 4vw;
        :after {
            right: -3vw;
            font-size: 3vw;
        }

    `}
  @media only screen and (max-width: 575px) {
    border-radius: 2.5vw;
    padding: 5.5vw 7.5vw 6vw 7.5vw;
    margin: 4.5vw 0;
    min-height: auto;
    margin-right: 0;
    margin-bottom: 7vw;
    :after {
      right: auto;
      transform: translate(-50%, 0);
      left: 50%;
      bottom: -11vw;
      top: auto;
      font-size: 11vw;
    }
  }
`;

export const TeacherHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.2vw;
  @media only screen and (max-width: 575px) {
    margin-bottom: 3vw;
  }
`;
export const TeacherImage = styled.img`
  width: 5.1vw;
  height: 5.1vw;
  border-radius: 100vw;
  overflow: hidden;
  border: 1.5px solid var(--granich-black);
  background-color: var(--granich-grey);
  @media only screen and (max-width: 575px) {
    width: 16.5vw;
    height: 16.5vw;
  }
  ${props =>
    props.numberOfTeachers === 3 &&
    `
        width: 4vw;
        height: 4vw;
    `}
`;
export const TeacherSocials = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  @media only screen and (max-width: 575px) {
    margin-right: 0;
  }
`;
export const TeacherSocialIcons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.6vw;
  margin-bottom: 0.5vw;
  svg {
    transition: all 0.2s ease;
    :hover {
      transform: scale(1.1);
    }
  }
  ${props =>
    props.numberOfTeachers === 3 &&
    `
        margin-bottom: 0.2vw;
    `}
  @media only screen and (max-width: 575px) {
    margin-top: 2vw;
    margin-bottom: 1.5vw;
  }
`;
export const TeacherEmail = styled.span`
  font-size: 0.8vw;
  font-weight: 400;
  display: block;
  color: var(--granich-grey);
  padding-bottom: 0.2vw;
  position: relative;
  margin-right: 0.4vw;
  background: none;
  border-bottom: 2px dotted rgba(0, 0, 0, 0.2);
  :hover {
    font-size: 0.8vw;
    color: var(--granich-grey);
    border-bottom: 2px dotted var(--granich-grey);
    cursor: pointer;
  }

  @media only screen and (max-width: 575px) {
    font-size: 3vw;
    padding-bottom: 0;
    :hover {
      font-size: 3vw;
      border-bottom: dotted 1.5px rgba(0, 0, 0, 0);
    }
    ${props =>
      props.masterclass &&
      `
            font-size: 2.8vw;
            :hover {
                font-size: 2.8vw;
    
            }
        `}
  }
`;
export const TeacherInfo = styled.div`
  min-height: 5.8vw;
  line-height: 1.35;

  @media only screen and (max-width: 575px) {
    min-height: 21vw;
  }
`;
export const TeacherInfoName = styled.div`
  font-weight: 600;
  font-size: 1.16vw;
  padding-bottom: 0.4vw;

  span {
    position: relative;
    left: 0.6vw;
    font-size: 0.8vw;
    border: 1px solid var(--granich-red);
    border-radius: 0.5vw;
    padding: 0.1vw 0.3vw;
    top: -0.1vw;
    color: var(--granich-red);
  }

  @media only screen and (max-width: 575px) {
    font-size: 4vw;
    line-height: 1.3;
    width: 67vw;
    padding-bottom: 1.5vw;
    span {
      position: relative;
      left: 3vw;
      font-size: 3vw;
      border: 1px solid var(--granich-red);
      border-radius: 2vw;
      padding: 0.6vw 1vw;
      top: -0.3vw;
      color: var(--granich-red);
    }
    ${props =>
      props.masterclass &&
      `
            font-size: 3.8vw;
        `}
  }
  ${props =>
    props.numberOfTeachers === 3 &&
    `
        font-size: 1vw;
    `}
`;

export const TeacherDescription = styled.div`
  font-size: 1.16vw;
  font-weight: 500;
  width: 18.5vw;
  p:empty {
    display: none;
  }

  p {
    color: var(--granich-grey);
    padding-bottom: 0.4vw;
    position: relative;
    padding-left: 0.7vw;
    :before {
      content: "";
      width: 0.3vw;
      height: 0.3vw;
      background: var(--granich-light-grey);
      position: absolute;
      border-radius: 50%;
      top: 0.5vw;
      left: 0;
    }
  }
  a {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    :hover {
      border-bottom: 1px solid var(--granich-grey);
    }
  }
  ${props =>
    props.numberOfTeachers === 3 &&
    `
        font-size: 1vw;
        width: 15.9vw;
    `}
  @media only screen and (max-width: 575px) {
    font-size: 4vw;
    line-height: 1.3;
    margin-top: 0;
    width: 67vw;
    p {
      color: var(--granich-grey);
      padding-bottom: 1.5vw;
      position: relative;
      padding-left: 3vw;

      :before {
        width: 1.3vw;
        height: 1.3vw;
        top: 1.8vw;
        left: 0;
      }
    }
  }
`;

export const SocialLink = styled.a``;
