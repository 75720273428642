// react/gatsby imports
import React from "react";
// components
import Layout from "components/layout";
import SEO from "components/layout/seo";
import CourseExplanations from "components/pages/course-page/explanations";
import CoursePriceRange from "components/pages/course-page/price-range";
import EducationQuestions from "components/pages/course-page/education-questions";
import CourseAuthors from "components/pages/course-page/authors";
import Mailing from "components/common/mailing";
import MasterClassPortfolio from "components/pages/course-page/portfolio/MasterClassPortfolio";
import SchoolSystem from "components/pages/course-page/school-system";
import MasterClassStream from "components/pages/course-page/master-class-stream";
import MasterClassTemplateOffer from "components/pages/course-page/offer/master-class";
import PaymentChoices from "components/pages/payment-choices";
// assets
import ogImage from "assets/images/seo/graphic-system-swiss.jpg";
import ogImageVK from "assets/images/seo/vk/graphic-system-swiss.jpg";
import swissFlag from "assets/images/swiss/swiss-flag.png";
// data
import teamData from "data/team";
import localData from "data/pages/graphic-system-swiss";
// hooks
import useSwissPortfolio from "hooks/portfolio/useSwissPortfolio";
import useSwissQuery from "hooks/graphql/useSwissQuery";
// utilities
import generateCanonicalLink from "utilities/link-utils";
// constants
import PAGE_SLUGS from "constants/page-slugs";

const GraphicSystemSwissPage = () => {
  const prismicData = useSwissQuery();
  const portfolio = useSwissPortfolio();

  const { data } = prismicData.course;
  const { data: seoData } = prismicData.page || {};
  const swissTeam = teamData.filter(
    ({ name }) =>
      name === "Вадим Гранич" ||
      name === "Андрей Павлушин" ||
      name === "Елизавета Черникова"
  );

  return (
    <Layout logo="graphic-system-swiss">
      <div className="section-top-block" />
      <SEO
        title="Графсистема Швейцария"
        description={
          seoData?.seodescription?.text ||
          "Швейцарский стиль (Swiss). Легендарный дизайн. Интернациональный типографический стиль. Обязателен к изучению. Нейтральность и точность обогатят вашу эстетику."
        }
        keywords={[
          "swiss",
          "графсистема",
          "гранич",
          "granich",
          "швейцария",
          "типографика",
          "сетка",
          "воздух",
        ]}
        ogImage={seoData?.seoimage?.url || ogImage}
        ogImageVk={seoData?.seoimage?.thumbnails?.vk?.url || ogImageVK}
        url={generateCanonicalLink(PAGE_SLUGS.graphicSystemSwiss)}
      />
      <MasterClassTemplateOffer
        courseName="Швейцария"
        data={data}
        icon={<img src={swissFlag} alt="swiss-flag" />}
      />
      <CourseExplanations data={localData.SwissExplanations} twoRows />
      <CourseAuthors
        numberOfTeachers={swissTeam.length}
        data={swissTeam}
        quote="Основное исследование — Андрей Павлушин. Дополнения, запись видеолекции и видеоинструкции — Елизавета Черникова. Дизайн-система, рекомендации и забота — Вадим Гранич."
        text={
          <>
            Швейцарский стиль — графсистема вне времени. Она не привязана к
            трендам, не имеет национальности. Её принципы можно использовать в
            любой отрасли дизайна, для любой аудитории. Поэтому она так важна.
            Многим Swiss кажется слишком сложной. Мы постарались разложить всё
            по полочкам и сделать её понятнее.
          </>
        }
      />
      <SchoolSystem />
      <MasterClassPortfolio
        courseTitle={data.coursetitle?.text}
        pinterestText={"Швейцария в Энциклопедии\nГрафдизайна Школы"}
        pinterestLink="https://www.pinterest.ru/vadim_granich/swiss-typography/"
        portfolio={portfolio}
        graphicSystemName="Швейцария"
      />
      <div id="participation-section" />
      {data.coursestatus ? (
        <CoursePriceRange
          formId="ltForm3153227"
          priceRange={localData.priceRange}
          courseTitle={data.coursetitle?.text}
          formPostUrl="https://school.granich.design/pl/lite/block-public/process-html?id=1457345083"
          data={localData.SwissPricesRange}
        />
      ) : (
        <Mailing />
      )}
      <PaymentChoices courseType="Мастер-класс" />
      <EducationQuestions />
      <MasterClassStream
        title="Учебный стрим по системе Швейцарии"
        description="По этой графсистеме на ютуб-канале Школы есть учебный стрим.
        Его можно посмотреть для разогрева. Если вам он понравится, то смело берите этот мастер‑класс."
        youtubeUrl="https://www.youtube.com/embed/EU8VAhqrhqA"
      />
    </Layout>
  );
};

export default GraphicSystemSwissPage;
