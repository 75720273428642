import React from "react";
import { Container } from "components/style";
import CopyMailToClipboardComponent from "components/common/copy-to-clipboard";
import { graphql, useStaticQuery } from "gatsby";
import * as SC from "./style";

const Authors = ({ data = [], numberOfTeachers, quote, text }) => (
  <SC.AuthorsSection>
    <Container>
      <SC.AuthorsWrapper>
        <SC.AuthorsTitle>Соавторы</SC.AuthorsTitle>
        <SC.AuthorsTeachers>
          {data.map((teamMember, idx) => (
            <MasterClassTeacherBlock
              key={idx}
              numberOfTeachers={numberOfTeachers}
              teamMember={teamMember}
            />
          ))}
        </SC.AuthorsTeachers>
        <SC.AuthorsText>
          <SC.AuthorsQuote>{quote}</SC.AuthorsQuote>
          <SC.AuthorsDescr>{text}</SC.AuthorsDescr>
        </SC.AuthorsText>
      </SC.AuthorsWrapper>
    </Container>
  </SC.AuthorsSection>
);

export default Authors;

export const MasterClassTeacherBlock = ({ numberOfTeachers, teamMember }) => {
  const data = useStaticQuery(graphql`
    query courseAuthorsSocialIcons {
      vk: file(relativePath: { eq: "team_socials/team_vk.png" }) {
        childImageSharp {
          fluid(maxWidth: 65) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      instagram: file(relativePath: { eq: "team_socials/team_instagram.png" }) {
        childImageSharp {
          fluid(maxWidth: 65) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pinterest: file(relativePath: { eq: "team_socials/team_pinterest.png" }) {
        childImageSharp {
          fluid(maxWidth: 65) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      telegram: file(relativePath: { eq: "team_socials/team_telegram.png" }) {
        childImageSharp {
          fluid(maxWidth: 65) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      behance: file(relativePath: { eq: "team_socials/team_behance.png" }) {
        childImageSharp {
          fluid(maxWidth: 65) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const vkIcon = data.vk.childImageSharp.fluid;
  const pinterestIcon = data.pinterest.childImageSharp.fluid;
  const instagramIcon = data.instagram.childImageSharp.fluid;
  const telegramIcon = data.telegram.childImageSharp.fluid;
  const behanceIcon = data.behance.childImageSharp.fluid;

  return (
    <SC.TeacherWrapper numberOfTeachers={numberOfTeachers}>
      <SC.TeacherHeader>
        <SC.TeacherImage
          numberOfTeachers={numberOfTeachers}
          src={teamMember.avatar}
        />
        <SC.TeacherSocials>
          <SC.TeacherSocialIcons numberOfTeachers={numberOfTeachers}>
            {teamMember.telegram && (
              <SC.SocialLink
                rel="noopener noreferrer"
                href={teamMember.telegram}
                target="_blank"
              >
                <SC.SocialIcon fluid={telegramIcon} />
              </SC.SocialLink>
            )}
            {teamMember.vk && (
              <SC.SocialLink
                rel="noopener noreferrer"
                href={teamMember.vk}
                target="_blank"
              >
                <SC.SocialIcon fluid={vkIcon} />
              </SC.SocialLink>
            )}
            {teamMember.instagram && (
              <SC.SocialLink
                rel="noopener noreferrer"
                href={teamMember.instagram}
                target="_blank"
              >
                <SC.SocialIcon fluid={instagramIcon} />
              </SC.SocialLink>
            )}
            {teamMember.behance && (
              <SC.SocialLink
                rel="noopener noreferrer"
                href={teamMember.behance}
                target="_blank"
              >
                <SC.SocialIcon fluid={behanceIcon} />
              </SC.SocialLink>
            )}
            {teamMember.pinterest && (
              <SC.SocialLink
                rel="noopener noreferrer"
                href={teamMember.pinterest}
                target="_blank"
              >
                <SC.SocialIcon fluid={pinterestIcon} />
              </SC.SocialLink>
            )}
          </SC.TeacherSocialIcons>
          {teamMember.email && (
            <CopyMailToClipboardComponent mail={teamMember.email}>
              <SC.TeacherEmail>{teamMember.email}</SC.TeacherEmail>
            </CopyMailToClipboardComponent>
          )}
        </SC.TeacherSocials>
      </SC.TeacherHeader>
      <SC.TeacherInfo>
        <SC.TeacherInfoName numberOfTeachers={numberOfTeachers}>
          {teamMember.name}
          <span>{teamMember.category}</span>
        </SC.TeacherInfoName>
        <SC.TeacherDescription
          className="our-team_link our-team_link_master-class"
          numberOfTeachers={numberOfTeachers}
        >
          {teamMember.aboutItems.map((item, idx) => (
            <p key={idx}>{item}</p>
          ))}
        </SC.TeacherDescription>
      </SC.TeacherInfo>
    </SC.TeacherWrapper>
  );
};
