/* eslint-disable */
import defaultPriceRange from "./default-price-range";
const priceRange = [
  {
    ...defaultPriceRange[0],
    price: 900,
    getcourseInputId: "3547348",
  },
  {
    ...defaultPriceRange[1],
    price: 1800,
    getcourseInputId: "3547353",
  },
  {
    ...defaultPriceRange[2],
    price: 2500,
    getcourseInputId: "3547356",
  },
  {
    ...defaultPriceRange[3],
    price: 3500,
    getcourseInputId: "3547360",
  },
];
export default {
  SwissExplanations: [
    {
      id: "ea3c9960-f880-5d8e-af95-d98b1f86359a",
      explanationsImage: {
        src: "/images/graphic-system.jpg",
      },
      explanationsTitle: "Что такое графсистема?",
      explanationsTextTitle:
        "Комбинация элементов и принципов по определённым правилам.",
      explanationsTextDescription:
        "Графсистема строится на правилах дизайна элементов и принципах вёрстки. Усиливает насмотренность дизайнера.",
    },
    {
      id: "c7878326-2114-5e39-9a7a-15be0e3fa7ef",
      explanationsImage: {
        src: "/images/graphic-system-swiss/explanations/02.jpg",
      },
      explanationsTitle: "Что такое графсистема Швейцарии?",
      explanationsTextTitle: "Швейцарский стиль (Swiss).",
      explanationsTextDescription:
        "Вычищенный стиль, созданный в Швейцарии. Swiss не теряет своей актуальности уже больше 70-ти лет. Используется в современной корпоративной айдентике. А благодаря огромному вниманию к типографике (искусству оформления текста) служит основой для многих других графсистем. Пространство макета проектируется сетками. Вы увидите швейцарский принцип Lagom в действии — не много и не мало, ровно в самый раз. Нейтральность и сдержанность обогатят вашу эстетику. Посвящается фанатам Дитера Рамса, мебели ИКЕА, и строгих корпоративных стилей.",
    },
  ],
  priceRange,
};
